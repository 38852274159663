import 'alpinejs';
import jsonp from 'jsonp';

window.newsletter = () => ({
	email: '',
	success: false,
	error: false,
	submit () {
		this.error = false;
		this.success = false;
		jsonp(`https://gambitnash.us10.list-manage.com/subscribe/post-json?u=4542bd171e0fd3e8484161edd&id=6c33b5255a&gdpr[30085]=Y&EMAIL=${encodeURIComponent(this.email)}`, { param: 'c' }, (err, { result }) => {
			if (err || result !== 'success') {
				this.error = true;
			} else {
				this.success = true;
			}
		});
	}
});

window.copyright = () => ({
	year: (new Date()).getFullYear()
});
